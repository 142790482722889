import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Popup } from '@progress/kendo-react-popup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots, faTimes } from '@fortawesome/free-solid-svg-icons'

import Chat from './Chat'

const Bot = (props) => {
  const {
    bot_api: botApi,
    bot_id: botId,
    bot_name: botName,
    start_trigger: startTrigger,
    open_by_button: openByButton,
    open_by_class: openByClass,
    open_after_time: openAfterTime,
    btn_background_color: btnBackgroundColor,
    icon_color: iconColor,
    position_left: showLeft,
  } = props
  const variables = _.omit(props, [
    'bot_api',
    'bot_id',
    'bot_name',
    'start_trigger',
    'open_by_button',
    'open_after_time',
    'btn_background_color',
    'icon_color',
  ])
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  const [showBot, setShowBot] = useState({ show: false, wasShown: false })
  const [sessionId, setSessionId] = useState(null)
  const user = { id: 1, name: 'Gast' }

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }
    window.addEventListener('resize', handleResize)

    if (openByClass) {
      for (const el of document.getElementsByClassName(openByClass)) {
        el.addEventListener('click', function (event) {
          setShowBot({ show: true, wasShown: true })
        })
      }
    }
  }, [openByClass])

  useEffect(() => {
    const createSession = async () => {
      const response = await axios.post(`${botApi}/sessions`, {
        botId,
        source: 'wordpress-plugin',
        ...variables,
      })
      const { id } = response.data
      setSessionId(id)
    }
    createSession()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId])

  useEffect(() => {
    if (openAfterTime && !showBot.wasShown) {
      const timer = setTimeout(() => {
        if (!showBot.wasShown && !showBot.show) {
          setShowBot({ show: true, wasShown: true })
        }
      }, _.toNumber(openAfterTime))
      // Clear timeout if the component is unmounted
      return () => clearTimeout(timer)
    }
  }, [openAfterTime, showBot])

  const popupClass = () => {
    if (dimensions.width <= 600) {
      return 'popup'
    }

    if (showLeft === 'true') {
      return 'popup-left'
    } else {
      return 'popup-right'
    }
  }

  const renderPopup = () => {
    return (
      <Popup
        show={true}
        className={popupClass()}
        style={{ display: showBot.show ? '' : 'none' }}
      >
        <div>
          <button
            onClick={() => setShowBot({ ...showBot, show: false })}
            className="close-popup"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <Chat
            api={botApi}
            sessionId={sessionId}
            botAuthor={{ id: 0, name: botName }}
            user={user}
            startTrigger={startTrigger}
            className="popupChat"
          />
        </div>
      </Popup>
    )
  }

  const renderWithButton = () => {
    return (
      <>
        <button
          id={`rtc-open-bot-${botId}`}
          onClick={() => setShowBot({ show: !showBot.show, wasShown: true })}
          className="k-button"
          style={{
            backgroundColor: btnBackgroundColor || '#ffffff',
          }}
        >
          <FontAwesomeIcon
            icon={faCommentDots}
            size="2x"
            color={iconColor || '#007cff'}
          />
        </button>
        {renderPopup()}
      </>
    )
  }

  if (!sessionId) return null

  if (openByButton === 'true') {
    return renderWithButton()
  } else if (openByClass !== '') {
    return renderPopup()
  } else {
    return (
      <Chat
        api={botApi}
        sessionId={sessionId}
        botAuthor={{ id: 0, name: botName }}
        user={user}
        startTrigger={startTrigger}
        className="chat"
      />
    )
  }
}

export default Bot
