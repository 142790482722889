import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './components/App'

const targets = document.querySelectorAll('.rtc-bot')
Array.prototype.forEach.call(targets, (target) => {
  const id = target.dataset.id
  const settings = window.rtcBotSettings[id]
  ReactDOM.render(<App settings={settings} />, target)
})
