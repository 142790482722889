import React, { useEffect, useReducer } from 'react'
import _ from 'lodash'
import axios from 'axios'

import '@progress/kendo-theme-material/dist/all.css'
import { Chat as KendoChat } from '@progress/kendo-react-conversational-ui'

const ADD_MESSAGE = 'add_message'
const SET_ERROR = 'set_error'

function messageReducer(state, action) {
  switch (action.type) {
    case ADD_MESSAGE:
      return { messages: [...state.messages, action.payload], error: null }
    case SET_ERROR:
      return { ...state, error: action.payload }
    default:
      return { ...state }
  }
}

const Chat = ({ api, sessionId, botAuthor, user, startTrigger, className }) => {
  const [{ messages }, dispatch] = useReducer(messageReducer, { messages: [] })

  const getBotResponse = async (type, value) => {
    try {
      const response = await axios.get(`${api}/conversation`, {
        params: { s: sessionId, t: type, v: value },
      })
      const { answers, suggestedActions, preScript } = response.data

      if (preScript) {
        // eslint-disable-next-line no-eval
        eval(preScript)
      }

      const answersWithoutActions =
        answers.length > 1 ? _.slice(answers, 0, answers.length - 1) : []

      const intervall = 500
      let counter = 1

      _.forEach(answersWithoutActions, (answer) => {
        setTimeout(() => {
          dispatch({
            type: ADD_MESSAGE,
            payload: {
              author: botAuthor,
              timestamp: new Date(),
              text: answer,
            },
          })
        }, counter * intervall)
        counter += 1
      })

      setTimeout(() => {
        dispatch({
          type: ADD_MESSAGE,
          payload: {
            author: botAuthor,
            timestamp: new Date(),
            text: _.last(answers),
            suggestedActions,
          },
        })
      }, counter * intervall)
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.message })
    }
  }

  useEffect(() => {
    if (startTrigger) {
      getBotResponse('trigger', startTrigger)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTrigger])

  const handleSendMessage = (event) => {
    dispatch({
      type: ADD_MESSAGE,
      payload: event.message,
    })
    getBotResponse('text', event.message.text)
  }

  const handleAction = (event) => {
    const message = {
      author: user,
      text: event.action.title,
      timetamp: new Date(),
    }
    dispatch({
      type: ADD_MESSAGE,
      payload: message,
    })
    getBotResponse(event.action.type, event.action.value)
  }

  return (
    <KendoChat
      user={user}
      messages={messages}
      onMessageSend={handleSendMessage}
      onActionExecute={handleAction}
      placeholder="Ihre Nachricht..."
      className={className}
    />
  )
}

export default Chat
